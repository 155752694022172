import useUrlState from '@ahooksjs/use-url-state';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import { Box, Card, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import LZString from 'lz-string';
import { SetStateAction, useEffect, useState } from 'react';
import { useAuthTokenAndAccessApi } from '../../auth/authHooks';
import CrossComparePlot from '../../components/InspectionDashboard/CrossComparePlot';
import DateRangePicker from '../../components/InspectionDashboard/DateFilters';
import DefaultPlot from '../../components/InspectionDashboard/DefaultPlot';
import DownloadChartData from '../../components/InspectionDashboard/DownloadChartData';
import AddReferenceLines from '../../components/InspectionDashboard/HorizontalReferenceLines';
import MultiBatchSelect from '../../components/InspectionDashboard/MultiBatchSelect';
import MultiMetricSelect from '../../components/InspectionDashboard/MultiMetricSelect';
import ScanFilterAutocomplete from '../../components/InspectionDashboard/ScanFilterAutocomplete';
import ScanPreviewerLayout from '../../components/InspectionDashboard/ScanPreviewer/ScanPreviewerLayout';
import SeriesCheckboxes from '../../components/InspectionDashboard/SeriesCheckboxes';
import XAxisToggle from '../../components/InspectionDashboard/XAxisToggle';
import { ScanList, SliceOrientation } from '../../types';
import { appTopNavBarHeight } from '../../utils';
import { ApiEndpoints } from '../../utils/apiUtils';
import { AutomatedInspectionMetrics } from '../../utils/inspection';
import {
    countRequestGroups,
    InspectionChartData,
    ScanPreviewState,
    XAxisOptions
} from '../../utils/inspection/dashboard';

export default function InspectionDashboardPage(
    {
        scanList,
        isDemoMode,
    }: {
        scanList: ScanList[],
        isDemoMode: boolean,
    }) {
    const { fetchData } = useAuthTokenAndAccessApi();
    const [urlStore, setUrlStore] = useUrlState()
    const [linkCopied, setCopied] = useState(false);

    const [rawChartData, setRawChartData] = useState<InspectionChartData[]>([]);
    // TODO: move to state:
    const requestsWithData = countRequestGroups(rawChartData)
    const availableMetricOptions = Array.from(new Set(rawChartData.map((e) => e.metric_internal_name)))

    const [isLoading, setLoadingStatus] = useState(true)
    const [xAxisType, setXAxisType] = useState(XAxisOptions.SCAN_ID);

    // filter states
    const [selectedMetrics, setSelectedMetrics] = useState<AutomatedInspectionMetrics[]>([])
    const [selectedRequests, setSelectedRequests] = useState<string[]>([]);
    const [filterScanIds, setFilterScanIds] = useState<number[] | null>(null)
    const [dateFilters, setDateFilters] = useState<{ start: Date | null, end: Date | null }>({ start: null, end: null });
    const [checkboxes, setCheckboxes] = useState({ max: true, min: true, mean: true, median: false });
    const [horizontalLines, setHorizontalLines] = useState<number[]>([]);

    const [previewState, setPreviewState] = useState<ScanPreviewState>({
        scanId: null,
        sliceId: null,
        metric: null,
        series: null,
        value: null,
    })

    // Layout state
    const textSx = { flexGrow: 1, pt: 2, pb: 1, pl: 1 }
    const leftPanelWidth = 350
    const rightPanelWidth = 400
    const metricTitleSx = { pt: 1 }

    const [chartWidth, setChartWidth] = useState(0)

    const handleResize = () => {
        const rightPanelOpen = previewState.scanId !== null
        const minWindowSize = 1500
        const otherElementsWidth = 100
        const panelSizes = rightPanelOpen
            ? leftPanelWidth + rightPanelWidth + otherElementsWidth
            : leftPanelWidth + otherElementsWidth
        window.innerWidth < minWindowSize ? setChartWidth(minWindowSize - panelSizes) : setChartWidth(window.innerWidth - panelSizes)
    }
    // resize listener
    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previewState])


    const saveToUrl = async () => {
        await setUrlStore({ dashboard: "" })
        const state = {
            sm: selectedMetrics, // Keep names short
            sr: selectedRequests,
            fsi: filterScanIds,
            c: checkboxes,
            hl: horizontalLines,
            df: dateFilters,
            xa: xAxisType,
            sp: previewState,
        };
        const compressed = LZString.compressToEncodedURIComponent(JSON.stringify(state));
        await setUrlStore({ dashboard: compressed })
        await navigator.clipboard.writeText(window.location.href).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        });
    }

    const resetSettings = () => {
        setFilterScanIds(null)
        setCheckboxes({ max: true, min: true, mean: true, median: false })
        setHorizontalLines([])
        setDateFilters({ start: null, end: null })
        setXAxisType(XAxisOptions.SCAN_ID)
        setUrlStore({ dashboard: undefined })
        setPreviewState({ scanId: null, sliceId: null, metric: null, series: null, value: null })
    }

    // url interfaces:
    const checkUrlState = () => {
        if (urlStore.dashboard) {
            try {
                const decompressed = LZString.decompressFromEncodedURIComponent(urlStore.dashboard);
                if (decompressed) {
                    const decodedState = JSON.parse(decompressed);
                    setSelectedMetrics(decodedState.sm || []);
                    setSelectedRequests(decodedState.sr || []);
                    setFilterScanIds(decodedState.fsi || null);
                    setCheckboxes(decodedState.c || { max: true, min: true, mean: true, median: false });
                    setHorizontalLines(decodedState.hl || []);
                    setDateFilters(decodedState.df || { start: null, end: null });
                    setXAxisType(decodedState.xa || XAxisOptions.SCAN_ID);
                    setPreviewState(decodedState.sp || { scanId: null, sliceId: null, metric: null, series: null, value: null })
                    return true
                }
            } catch (e) {
                console.error("Error decoding state from URL", e);
            }
        }
        return false
    }

    // data loading:
    useEffect(() => {
        setRawChartData([]);
        setLoadingStatus(true)
        setSelectedMetrics([])
        setSelectedRequests([])
        setFilterScanIds(null)
        async function initialDataLoader(isDemoMode: boolean) {
            getChartData(isDemoMode)
        }
        if (scanList.length > 0) initialDataLoader(isDemoMode)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scanList, isDemoMode]);


    const handleAxisChange = (e: SetStateAction<XAxisOptions>) => {
        if ([XAxisOptions.CROSS_COMPARE, XAxisOptions.BATCH].includes(e.toString() as XAxisOptions)) {
            setPreviewState({ scanId: null, sliceId: null, metric: null, series: null, value: null })
        }
        setXAxisType(e)
    }


    async function getChartData(isDemoMode: boolean) {
        try {
            const params: any = isDemoMode ? { is_demo: true } : {};
            const response = await fetchData(ApiEndpoints.INSPECTION_CHART_RESULTS, params);
            const data = response.data
            const joinedData = data
                .map((e: {
                    metric_internal_name: string,
                    orientation: SliceOrientation,
                    scan_id: number,
                    min: number,
                    max: number,
                    mean: number,
                    median: number,
                    min_slice_id: number,
                    max_slice_id: number,
                }
                ) => {
                    const foundData = scanList.find((s) => s.scan_id === e.scan_id);
                    if (!foundData || !foundData.request_id || !foundData.request_name || !foundData.post_processing_completed) {
                        return null;
                    }
                    return {
                        ...e,
                        sn: foundData.cell_sn,
                        request_id: foundData.request_id,
                        request_name: foundData.request_name,
                        scan_date: new Date(foundData.post_processing_completed)
                    };
                })
                .filter(Boolean) as InspectionChartData[];
            setRawChartData(joinedData)
            // if initial load check the url store
            if (!checkUrlState()) {
                if (isDemoMode && selectedRequests.length === 0 && selectedMetrics.length === 0) {
                    setSelectedMetrics(
                        [AutomatedInspectionMetrics.ANODE_OVERHANG_ALL,
                        AutomatedInspectionMetrics.CORE_AREA,
                        AutomatedInspectionMetrics.CATHODE_WIDTH]
                    )
                    setSelectedRequests(["Demo: 2170 cylindrical"])
                }
            }
            setLoadingStatus(false)
        } catch (error: any) { console.error(error) }
    }


    // Filter data based on selected metrics and requests
    const filterMetricsAndRequests = rawChartData
        .filter((e) => selectedMetrics.includes(e.metric_internal_name))
        .filter((e) => selectedRequests.includes(e.request_name))
        .filter((e) => dateFilters.start === null || e.scan_date >= dateFilters.start)
        .filter((e) => dateFilters.end === null || e.scan_date <= dateFilters.end);

    const uniqueScans = filterMetricsAndRequests.reduce<{ scan_id: number; sn: string; scan_date: Date }[]>((acc, curr) => {
        if (!acc.some(item => item.scan_id === curr.scan_id)) {
            acc.push({ scan_id: curr.scan_id, sn: curr.sn, scan_date: curr.scan_date });
        }
        return acc;
    }, []);

    const filterAll = filterScanIds === null ?
        filterMetricsAndRequests :
        filterMetricsAndRequests.filter((e) => filterScanIds.includes(e.scan_id))

    const plotArea = () => {
        if (
            filterAll.length === 0 && !isLoading) {
            return <Typography variant="body1" sx={textSx} >
                {
                    selectedMetrics.length === 0 || selectedRequests.length === 0 ?
                        "Select at least one batch and one metric." :
                        "No data, check filters."
                }
            </Typography>
        }
        if (xAxisType === XAxisOptions.CROSS_COMPARE) {
            if (selectedMetrics.length < 2) {
                return <Typography variant="body1" sx={textSx} >
                    Select at least two metrics for cross comparison.
                </Typography>
            }
            // TODO: dial in the resize logic and move around maybe?
            const chartSize = Math.min(
                selectedMetrics.length === 2 ? chartWidth * 0.85 : chartWidth * 0.48,
                700
            );
            return <CrossComparePlot
                data={filterAll}
                plotDims={{ width: chartSize, height: chartSize }}
                checkboxes={checkboxes}
            />
        } else {
            let width = chartWidth
            const heightMap = { 4: 190, 3: 210, 2: 270, 1: 550 } as Record<number, number>;
            const height = heightMap[selectedMetrics.length] || 180; // make charts smaller if there's a few selected

            return <DefaultPlot
                data={filterAll}
                plotDims={{ width: width, height: height }}
                xAxisOption={xAxisType}
                horizontalLines={horizontalLines}
                previewState={previewState}
                setPreviewState={setPreviewState}
                checkboxes={checkboxes}
            />
        }
    }
    if (isLoading) {
        return (
            <Box sx={{ height: '100%' }} >
                <Typography variant="body1" sx={textSx} >
                    Loading...
                </Typography>
            </Box>
        )
    }
    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{ p: 0, m: 0, height: `calc(100vh - ${appTopNavBarHeight}px)` }}
        >
            <Stack direction="column" spacing={1} sx={{
                pl: 1, pr: 1, minWidth: leftPanelWidth, width: leftPanelWidth, overflowY: 'auto',
            }} >
                <Typography sx={metricTitleSx}>Batches</Typography>
                <MultiBatchSelect
                    requestsWithData={requestsWithData}
                    selectedRequests={selectedRequests}
                    setSelectedRequests={setSelectedRequests}
                />

                <Typography sx={metricTitleSx}>Metrics</Typography>
                <MultiMetricSelect
                    selectedMetrics={selectedMetrics}
                    availableMetricOptions={availableMetricOptions}
                    setSelectedMetrics={setSelectedMetrics}
                />

                <Typography sx={metricTitleSx}>Scan dates</Typography>
                <DateRangePicker
                    dateFilters={dateFilters}
                    setDateFilters={setDateFilters} />

                <Typography sx={metricTitleSx}>Individual scans</Typography>
                <ScanFilterAutocomplete
                    filterScanIds={filterScanIds}
                    uniqueScans={uniqueScans}
                    setFilterScanIds={setFilterScanIds}
                />

                <Typography sx={metricTitleSx}>X-axis</Typography>
                <XAxisToggle xAxisType={xAxisType} setXAxisType={e => handleAxisChange(e)} />

                <Typography sx={metricTitleSx}>Data series</Typography>
                <SeriesCheckboxes checkboxes={checkboxes} setCheckboxes={setCheckboxes} />

                {
                    xAxisType !== XAxisOptions.CROSS_COMPARE &&
                    <>
                        <Stack direction="column" spacing={2} >
                            <Typography sx={metricTitleSx}>Reference Lines</Typography>
                            <AddReferenceLines
                                values={horizontalLines}
                                setValues={setHorizontalLines}
                            />
                        </Stack>
                    </>
                }

                <Stack direction="row" spacing={1} sx={{ pt: 2, pb: 2 }} justifyContent={'left'}>
                    <DownloadChartData data={filterAll} />
                    <Tooltip title={<Typography variant='body2'>
                        {!linkCopied ? "Save the current dashboard settings to the URL and copy the link." : "Copied the link to clipboard"}
                    </Typography>}>
                        <IconButton onClick={() => { saveToUrl() }}>
                            <IosShareOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<Typography variant='body2'>{"Reset to default settings."}</Typography>}>
                        <IconButton onClick={() => { resetSettings() }}>
                            <RestartAltOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
            <Card
                variant='outlined'
                sx={{
                    pb: 2,
                    pl: 1,
                    pt: 2,
                    minWidth: chartWidth,
                    width: chartWidth,
                    overflowY: 'auto',
                    borderRadius: 0,
                    borderBottom: 0,
                    borderTop: 0,
                }}>
                {plotArea()}
            </Card>
            {previewState.scanId !== null &&
                <>
                    <Stack
                        direction="column"
                        sx={{ minWidth: rightPanelWidth, width: rightPanelWidth, overflowY: 'auto', pt: 2, }}
                    >
                        <ScanPreviewerLayout
                            previewState={previewState}
                            selectedOrientation={rawChartData.find((e) => e.metric_internal_name === previewState.metric)?.orientation || null}
                            rightPanelWidth={rightPanelWidth}
                            isDemoMode={isDemoMode}
                            scanList={scanList}
                            clearSelection={() => { setPreviewState({ scanId: null, sliceId: null, metric: null, series: null, value: null }) }}
                        />
                    </Stack>
                </>
            }
        </Stack >
    );
}
