import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { ScanList, SliceOrientation } from '../../../types';
import { formatMetadata, newTabLink } from '../../../utils';
import { MetricDisplayNames, metricDisplayUnitSliceViewer } from '../../../utils/inspection';
import { formatMetricName, ScanPreviewState } from '../../../utils/inspection/dashboard';
import { borderColor } from '../../../utils/tableFormats';

export default function TopDataTable({
    previewState,
    selectedOrientation,
    scanList,
    selectedSlicePosition,
    isDemoMode
}: {
    previewState: ScanPreviewState,
    selectedOrientation: SliceOrientation | null,
    scanList: ScanList[],
    selectedSlicePosition: number | null,
    isDemoMode: boolean
}) {
    const filteredScanData = scanList
        .filter(scan => scan.scan_id === previewState.scanId)
        .flatMap(scan => {
            const baseData = [
                { label: "Serial number", value: scan.cell_sn },
                { label: "Cell vendor", value: scan.cell_model_vendor },
                { label: "Cell model name", value: scan.cell_model_name },
                { label: "Batch name", value: scan.request_name },
                { label: "Scan ID", value: scan.scan_id },
                { label: "Scan date", value: new Date(scan.post_processing_completed).toLocaleString() },
            ];
            if (scan.metadata) { baseData.push({ label: "Context data", value: formatMetadata(scan.metadata) }) }
            return baseData;
        });

    const metricDescriptionFinder = (metric: string | null) => {
        if (metric) { return MetricDisplayNames.find(e => e.internal_name === metric)?.description || "" }
        return ""
    }

    const generalData = [
        { label: "Metric", value: previewState.metric ? formatMetricName(previewState.metric) : "" },
        { label: "Metric description", value: metricDescriptionFinder(previewState.metric) },
        { label: "Series", value: previewState.series },
        { label: "Value", value: previewState.value?.toPrecision(4) + " " + metricDisplayUnitSliceViewer(previewState.metric as string) },
    ];

    const combinedData = [...filteredScanData, ...generalData];
    const constructLink = () => {
        const scanId = previewState.scanId ? previewState.scanId : 0
        return newTabLink(scanId, isDemoMode) + (selectedOrientation && selectedSlicePosition ? `&${selectedOrientation}=${selectedSlicePosition}` : "")
    }
    const linkItem = <Link href={constructLink()} target="_blank" >{previewState.scanId} <OpenInNewIcon fontSize='inherit' /></Link>

    return (<>
        <TableContainer sx={{ mt: 2, mb: 2 }}>
            <Table size="small">
                <TableBody >
                    {combinedData.map((item, index) => (
                        <TableRow key={index} sx={{ borderTop: 0.2, borderBottom: 0.2, borderColor: borderColor }
                        } >
                            <TableCell sx={{ border: 0 }}>{item.label}</TableCell>
                            <TableCell sx={{ border: 0 }}>{item.label === "Scan ID" ? linkItem : item.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
    );
}
