import { Box, Checkbox, Chip, FormHelperText, ListItemText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';



export default function MultiBatchSelect(
    {
        requestsWithData,
        selectedRequests,
        setSelectedRequests
    }: {
        requestsWithData: {
            request_name: string;
            count: number;
        }[],
        selectedRequests: string[]
        setSelectedRequests: (selectedRequests: string[]) => void;
    }) {

    const handleSelectAllRequests = () => {
        if (requestsWithData.length !== selectedRequests.length) {
            setSelectedRequests(requestsWithData.map((e) => e.request_name));
        } else {
            setSelectedRequests([]);
        }
    };

    const handleChange = (event: SelectChangeEvent<typeof selectedRequests>) => {
        const { target: { value } } = event;
        setSelectedRequests(
            // On autofill we get a stringified value.
            typeof value === 'string' ?
                value.split(',') :
                value
        );
    };

    return <FormControl >
        <Select
            multiple
            value={selectedRequests}
            onChange={handleChange}
            renderValue={(selected) => (
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                    maxHeight: 90,
                    overflowY: 'auto',
                }}>
                    {selected.map((e) => (<Chip variant='outlined' key={e} label={e} color='primary' sx={{ borderRadius: 1 }} />))}
                </Box>
            )}
            size='small'
            MenuProps={{ PaperProps: { sx: { maxHeight: 600, overflowY: 'auto' } } }}
        >
            <MenuItem dense >
                <Checkbox
                    sx={{ p: 0.5, m: 0 }}
                    checked={selectedRequests.length === requestsWithData.length}
                    onChange={handleSelectAllRequests} />
                <ListItemText primary={"All"} />
            </MenuItem>
            {requestsWithData.map((option) => (
                <MenuItem dense key={option.request_name} value={option.request_name}>
                    <Checkbox
                        sx={{ p: 0.5, m: 0 }}
                        checked={selectedRequests.indexOf(option.request_name) > -1}
                    />
                    <ListItemText primary={
                        option.request_name
                    } />
                </MenuItem>
            ))}
        </Select>
        <FormHelperText error={selectedRequests.length === 0}>{selectedRequests.length === 0 ? "Select at least one batch" : `Viewing ${selectedRequests.length} of ${requestsWithData.length} batches`}</FormHelperText>
    </FormControl>

}
